export const en = {
  sciece: "Science",
  climbing: "Alpinism & Climbing",
  menu: {
    home: "Home",
    about: "About Me",
    academicCv: "Academic CV",
    climbingCv: "Climbing CV",
    research: "Research",
    publication: "Publications",
    cooperation: "Cooperation",
    audVid: "Audio/Video",
    lectures: "Lectures",
    climbs: "Climbs",
    writings: "Writings",
    partners: "Partners",
    awards: "Awards",
    climbingPartners: "Climbing Partners",
    photo: "Photo",
    contact: "Contact",
  },
};

export const cz = {
  sciece: "Věda",
  climbing: "Alpinismus & Lezení",
  menu: {
    home: "Domů",
    about: "O mně",
    academicCv: "Akademické CV",
    climbingCv: "Lezecké CV",
    research: "Výzkumy",
    publication: "Publikace",
    cooperation: "Spolupráce",
    audVid: "Audio/Video",
    lectures: "Přednášky",
    climbs: "Výstupy",
    writings: "Texty",
    partners: "Partneři",
    awards: "Ocenění",
    climbingPartners: "Spolulezci",
    photo: "Foto",
    contact: "Kontakt",
  },
};
