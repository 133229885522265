// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alpinism-about-js": () => import("./../../../src/pages/alpinism-about.js" /* webpackChunkName: "component---src-pages-alpinism-about-js" */),
  "component---src-pages-alpinism-awards-js": () => import("./../../../src/pages/alpinism-awards.js" /* webpackChunkName: "component---src-pages-alpinism-awards-js" */),
  "component---src-pages-alpinism-climbing-partners-js": () => import("./../../../src/pages/alpinism-climbing-partners.js" /* webpackChunkName: "component---src-pages-alpinism-climbing-partners-js" */),
  "component---src-pages-alpinism-climbs-js": () => import("./../../../src/pages/alpinism-climbs.js" /* webpackChunkName: "component---src-pages-alpinism-climbs-js" */),
  "component---src-pages-alpinism-contact-js": () => import("./../../../src/pages/alpinism-contact.js" /* webpackChunkName: "component---src-pages-alpinism-contact-js" */),
  "component---src-pages-alpinism-eiger-pdf-js": () => import("./../../../src/pages/alpinism-eiger-pdf.js" /* webpackChunkName: "component---src-pages-alpinism-eiger-pdf-js" */),
  "component---src-pages-alpinism-lectures-js": () => import("./../../../src/pages/alpinism-lectures.js" /* webpackChunkName: "component---src-pages-alpinism-lectures-js" */),
  "component---src-pages-alpinism-media-js": () => import("./../../../src/pages/alpinism-media.js" /* webpackChunkName: "component---src-pages-alpinism-media-js" */),
  "component---src-pages-alpinism-partners-js": () => import("./../../../src/pages/alpinism-partners.js" /* webpackChunkName: "component---src-pages-alpinism-partners-js" */),
  "component---src-pages-alpinism-photo-js": () => import("./../../../src/pages/alpinism-photo.js" /* webpackChunkName: "component---src-pages-alpinism-photo-js" */),
  "component---src-pages-alpinism-tilak-pdf-js": () => import("./../../../src/pages/alpinism-tilak-pdf.js" /* webpackChunkName: "component---src-pages-alpinism-tilak-pdf-js" */),
  "component---src-pages-alpinism-writings-js": () => import("./../../../src/pages/alpinism-writings.js" /* webpackChunkName: "component---src-pages-alpinism-writings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-science-about-js": () => import("./../../../src/pages/science-about.js" /* webpackChunkName: "component---src-pages-science-about-js" */),
  "component---src-pages-science-awards-js": () => import("./../../../src/pages/science-awards.js" /* webpackChunkName: "component---src-pages-science-awards-js" */),
  "component---src-pages-science-bittner-js": () => import("./../../../src/pages/science-bittner.js" /* webpackChunkName: "component---src-pages-science-bittner-js" */),
  "component---src-pages-science-contact-js": () => import("./../../../src/pages/science-contact.js" /* webpackChunkName: "component---src-pages-science-contact-js" */),
  "component---src-pages-science-cooperation-js": () => import("./../../../src/pages/science-cooperation.js" /* webpackChunkName: "component---src-pages-science-cooperation-js" */),
  "component---src-pages-science-cv-js": () => import("./../../../src/pages/science-cv.js" /* webpackChunkName: "component---src-pages-science-cv-js" */),
  "component---src-pages-science-halasz-js": () => import("./../../../src/pages/science-halasz.js" /* webpackChunkName: "component---src-pages-science-halasz-js" */),
  "component---src-pages-science-klobucky-js": () => import("./../../../src/pages/science-klobucky.js" /* webpackChunkName: "component---src-pages-science-klobucky-js" */),
  "component---src-pages-science-lectures-js": () => import("./../../../src/pages/science-lectures.js" /* webpackChunkName: "component---src-pages-science-lectures-js" */),
  "component---src-pages-science-publications-js": () => import("./../../../src/pages/science-publications.js" /* webpackChunkName: "component---src-pages-science-publications-js" */),
  "component---src-pages-science-research-js": () => import("./../../../src/pages/science-research.js" /* webpackChunkName: "component---src-pages-science-research-js" */),
  "component---src-pages-science-streit-js": () => import("./../../../src/pages/science-streit.js" /* webpackChunkName: "component---src-pages-science-streit-js" */)
}

